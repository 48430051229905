.card {
  background: #161515;
  border-radius: 15px;
  height: 280px;
  overflow: hidden;
  border: 1px solid transparent;
  margin: 25px 17px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.des_bx {
  height: 230px;
}

.socialMedia_bx .wrapper {
  width: 100%;
  height: 50px;
  /* background: rgba(217, 217, 217, 0.1); */
  position: relative;
  /* transform: scaleY(0);
   transition: all;
   transition-duration: 500ms;
   transform-origin: bottom; */
}
.card .box {
  cursor: pointer;
}
.card:hover {
  border: 1px solid white;
  box-shadow: 0px -1px 20px 11px #ffa5005e;
}
.socialMedia_bx:hover {
  transform: scaleY(1);
}
.card:hover .socialMedia_bx .wrapper {
  transform: scaleY(1);
}
.icon:hover {
  transform: scale(0.95);
}
.main_slider_wrapper .swiper-slide {
  /* margin: 0 !important; */
}
