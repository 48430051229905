.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.event_banner {
  position: relative;
}

.event_banner::after {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 10;
  opacity: 1;
  content: "";
  height: 60px;
  bottom: 0px;

  background: linear-gradient(1turn, rgb(15, 15, 15), transparent);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.bg-domain-box {
  backdrop-filter: blur(18px);
  box-shadow: inset 0 0px 20px white;

}

.bg-domain {
  background-image: radial-gradient(linear, #88499B33, #00000040, #00000040);

}

.parrent_wrapper .parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parrent_wrapper .div1 {
  grid-area: 1 / 1 / 4 / 5;
  /* background: red; */
}

.parrent_wrapper .div2 {
  grid-area: 1 / 5 / 4 / 7;
/* background: blue; */
}

.parrent_wrapper .div3 {
  grid-area: 4 / 1 / 7 / 3;
  /* background: yellow; */
}

.parrent_wrapper .div4 {
  grid-area: 4 / 3 / 7 / 5; 
  /* background: greenyellow; */
}
.parrent_wrapper .div5 {
  grid-area: 4 / 5 / 7 / 7;
  /* background: greenyellow; */
}
.parrent_wrapper .recent_img_wrapper .text{
  transform: translateY(200px);
  /* transition: cubic-bezier(0.165, 0.84, 0.44, 1); */

}
.parrent_wrapper .recent_img_wrapper .box:hover .text {
  transform: translateY(0px);
}
@media (max-width: 768px) {
  .parrent_wrapper .parent {
    grid-template-columns: 100%; /* Set grid to one column */
  }
  
  .parrent_wrapper .div1,
  .parrent_wrapper .div2,
  .parrent_wrapper .div3,
  .parrent_wrapper .div4,
  .parrent_wrapper .div5 {
    width: 100%; /* Set width of each photo to 100% */
  }
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */