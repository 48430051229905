@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playwrite+US+Trad:wght@100..400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
.playwrite-us-trad-annocment {
  font-family: "Playwrite US Trad", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0F0F0F;
  color: white;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

.container {
  /* Basic dimensions and centering */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Dark mode colors and gradient */
  background: #121212;
  /* Fallback for browsers that don't support gradients */
  background: linear-gradient(135deg,
      #121212 25%,
      #1a1a1a 25%,
      #1a1a1a 50%,
      #121212 50%,
      #121212 75%,
      #1a1a1a 75%,
      #1a1a1a);
  background-size: 40px 40px;

  /* Animation */
  animation: move 4s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 40px 40px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.demo {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #34495e;
  color: wheat;
  font-size: 25px;
}

footer {
  color: white;
}

.title {
  font-weight: 700;
}

.title .higlight {
  color: #FF7D36;
}

.about_wrapper .list {
  display: flex;
  gap: 10px;
  align-items: start;

}

.about_wrapper .icon {
  color: #FF7D36;

}


#controllerBTn {
  --color: #0077ff;
  font-family: inherit;
  display: inline-block;
  width: 6em;
  height: 2.6em;
  line-height: 2.5em;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  font-size: 17px;
  z-index: 1;
  color: var(--color);
  border: 2px solid var(--color);
  border-radius: 6px;
  position: relative;
}

#controllerBTn::before {
  position: absolute;
  content: "";
  background: var(--color);
  width: 150px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
}

#controllerBTn:hover {
  color: white;
}

#controllerBTn:before {
  top: 100%;
  left: 100%;
  transition: 0.3s all;
}

#controllerBTn:hover::before {
  top: -30px;
  left: -30px;
}

#project_area {
  /* background-color: url("./assets/images/GEO_SPHERES\ 03.png") */
}

.team_main_bop_wrapper:hover img {
  transform: scale(1.2);
}

.team_main_bop_wrapper:hover {
  box-shadow: 1px 1px 15px 1px #f97f515e;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.memberBoxWrapper .img_wrapper:hover img {
  transform: scale(1.01);
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

